import { gql } from "@apollo/client"
import { Sprint } from '../../models/sprint';

export interface GetAllSprintsData {
  sprints: Sprint[];
}

export const GetAllSprints = gql`
query {
    sprints {
      id
      title
      releaseDate
    }
  }
`;