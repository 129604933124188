import { Table } from '@mantine/core';
import moment from 'moment';
import { Sprint } from '../../../models/sprint';

interface SprintTableProps {
    sprints: Sprint[]
} 

export function SprintTable ({ sprints }: SprintTableProps) {
    const sortedSprints = Array.from(sprints).reverse();
    const rows = sortedSprints.map((sprint, index)=> (<tr key={index}>
        <td>{sprint.id}</td>
        <td>{sprint.title}</td>
        <td>{moment(sprint.releaseDate).format('DD/MM/YYYY')}</td>
    </tr>));
    
    return (<Table striped highlightOnHover>
            <thead>
                <tr>
                    <th>Sprint ID</th>
                    <th>Name</th>
                    <th>Release Date</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>)
}
