import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'tabler-icons-react';
import { UnstyledButton, Group, Avatar, Text, Box, useMantineTheme } from '@mantine/core';
import { Me } from '../../../api/queries/user';
import { useQuery } from '@apollo/client';
import { Skeleton, Popover, Button, Center } from '@mantine/core';
import { Logout } from 'tabler-icons-react';
import { logout } from '../../../helpers/Authentication';
import { useNavigate } from 'react-router-dom';

export function User() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);

  const { data, loading, error } = useQuery(Me);
  if (error) {
    return (<>Failed loading profile data.</>)
  }
  return (

    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
          }`,
      }}
    >
      <Popover
        opened={opened}
        sx={{ width: '100%' }}
        onClose={() => setOpened(false)}
        target={<UnstyledButton
          onClick={() => setOpened((o) => !o)}
          sx={{
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
          }}
        >
          <Group>
            {loading
              ? <Skeleton height={40} circle mb="xl" />
              : <Avatar
                color={'cyan'}
                radius="xl"
              >{data.me.username[0]}</Avatar>
            }

            <Box sx={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {loading
                  ? <Skeleton height={10} />
                  : data.me.username.substring(0, 13)
                }
              </Text>
              <Text color="dimmed" size="xs">
                {loading
                  ? <Skeleton height={10} />
                  : `${data.me.email.substring(0, 8)}...`
                }
              </Text>
            </Box>

            {theme.dir === 'ltr' ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
          </Group>
        </UnstyledButton>}
        width={260}
        position="right"
        withArrow
      >
        <Center>

          <Button onClick={()=>{logout(); navigate('/login');}} variant="gradient" gradient={{ from: 'orange', to: 'red' }}>
             <Logout></Logout>  Logout
             </Button>
        </Center>
      </Popover>
    </Box>
  );
}