import React from 'react';
import { Sun, MoonStars } from 'tabler-icons-react';
import { AppShell, Navbar, Header, Group, ActionIcon, useMantineColorScheme } from '@mantine/core';
import { MainLinks } from '../MainLinks';
import { User } from '../Users';
import { Logo } from '../Logo';
import {
  Outlet,
} from "react-router-dom";
import { CurrentSprintIndicator } from '../../Sprint/CurrentSprintIndicator';
import { Global } from '../../Fetch/Global';

export function Layout() : JSX.Element {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Global>
      <AppShell 
        fixed={true}
        padding="md"
        navbar={
          <Navbar width={{ base: 250 }} p="xs">
            <Navbar.Section grow mt="xs">
              <MainLinks />
            </Navbar.Section>
            <Navbar.Section>
              <User />
            </Navbar.Section>
          </Navbar>
        }
        header={
          <Header height={60}>
            <Group sx={{ height: '100%' }} px={20} position="apart">
              <Logo colorScheme={colorScheme} />
              <CurrentSprintIndicator/>
              <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
                {colorScheme === 'dark' ? <Sun size={16} /> : <MoonStars size={16} />}
              </ActionIcon>
            </Group>
          </Header>
        }
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        })}
      >
        {<Outlet/>}
      </AppShell>
    </Global>
  );
}
