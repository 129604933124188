import { Anchor, Button, Text } from '@mantine/core';
import { Sprint } from '../../../models/sprint';
import { Task } from '../../../models/task';
import { rcReleaseEmail } from './templates';

interface EmailBuilderProps {
    tasks: Task[]
    sprint: Sprint
} 

interface DownloaderProps {
    tasks: Task[]
    sprint: Sprint
    report: 'rc' | 'prod'
} 


const Downloader = (props: DownloaderProps)=> () => {
    const renderedEmail = rcReleaseEmail(props);
    const element = document.createElement("a");
    const file = new Blob([renderedEmail], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "rc-release.txt";
    document.body.appendChild(element);
    element.click();
  };

function EmailBuilder (props: EmailBuilderProps) {
    return (<>
        <Button sx={{margin: '0 10px 0 0'}} onClick={Downloader({...props, report: 'rc'})}>Download RC</Button>
        <Button onClick={Downloader({...props, report: 'prod'})}>Download Prod</Button>
        <Text> <Anchor href='https://mjml.io/try-it-live' >Compile your downloaded email here</Anchor></Text>
    </>)
}


export default EmailBuilder;