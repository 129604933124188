import { useRecoilState} from 'recoil';
import { currentSprintState } from '../../store/atoms/sprint';
import { currentTasksState } from '../../store/atoms/task';
import { useQuery } from '@apollo/client';
import { GetAllSprints, GetAllSprintsData } from '../../api/queries/sprint';
import { useEffect } from 'react';
import { TasksbySprint } from '../../api/queries/task';

type Props = {
    children: JSX.Element,
};


export const Global = ({children}: Props) => {
    const [currentSprint, setCurrentSprint] = useRecoilState(currentSprintState);
    const [currentTasks, setCurrentTasks] = useRecoilState(currentTasksState);

    const { data: sprintData, loading: sprintloading } =  useQuery<GetAllSprintsData>(GetAllSprints);
    let newSprintState = sprintData
    ? {...sprintData.sprints[sprintData.sprints.length -1], loading: sprintloading}
    : {...currentSprint};
    
    const { data: tasksData, loading: tasksLoading, error: tasksError } = useQuery(TasksbySprint, {
        skip: !newSprintState.id,
        variables: {sprintId: newSprintState.id}
    });
    let newTasksState = tasksData ? {...tasksData, loading: tasksLoading, error: tasksError} : currentTasks;

    useEffect(() => {
            setCurrentSprint(newSprintState);
            setCurrentTasks(newTasksState);
    },[tasksLoading]);

    return children;
}