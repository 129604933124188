import { atom } from "recoil";
import { Sprint } from "../../models/sprint";
  
interface SprintState extends Sprint {
    loading: boolean
}

export const currentSprintState = atom({
    key: 'currentSprintState',
    default: {id: 0, releaseDate: '', loading: true} as SprintState,
  });