import './App.css';
import { useState } from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation
} from "react-router-dom";
import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';
import Login from './pages/Login';
import { ApolloClient, concat, HttpLink, ApolloLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { loggedIn } from './helpers/Authentication';
import { Layout } from './components/Layout/Layout';
import Dashboard from './pages/Dashboard';
import EmailBuilder from './pages/EmailBuilder';
import Sprints from './pages/Sprints';
import {
  RecoilRoot
} from 'recoil';

function RequireAuth({ children }: { children: JSX.Element }) {
  let isLoggedIn = loggedIn();
  let location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}


function App() {
  const httpLink = new HttpLink({uri: 'https://api.webtrack-sprint.atms.dev/graphql'});
  const authMiddleware = new ApolloLink((operation, forward)=> {
      
      const JWT = localStorage.getItem('access-token');
      if (JWT) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${JWT}`
          }
        });
      } 

      return forward(operation);
  });

  const apolloClient = new ApolloClient({
    link: concat(authMiddleware,httpLink),
    cache: new InMemoryCache()
  });

  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  return (
    <Router>
      <RecoilRoot>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider theme={{ colorScheme }}>
            <ApolloProvider client={apolloClient}>
              <Routes>
                <Route element={<RequireAuth><Layout/></RequireAuth>}>
                  <Route path="/" element={ <Navigate to="/currentSprint"  /> }/>
                  <Route path="/currentSprint" element={<Dashboard/>} />
                  <Route path="/emailBuilder" element={<EmailBuilder />} />
                  <Route path="/sprints" element={<Sprints />} />

                  {/* Using path="*"" means "match anything", so this route
                        acts like a catch-all for URLs that we don't have explicit
                        routes for. */}
                </Route>
                <Route path="/login" element={<Login />} />
              </Routes>
            </ApolloProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </RecoilRoot>
    </Router>
    
  );
}

export default App;
