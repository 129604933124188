import { atom } from "recoil";
import { Task } from "../../models/task";
  
interface TasksState {
    tasks: Task[]
    loading: boolean
    error: boolean
    
}

export const currentTasksState = atom({
    key: 'currentTasksState',
    default: {tasks: [], loading: true, error: false} as TasksState,
  });