import React from 'react';
import { Subtask, Mail, CurrentLocation } from 'tabler-icons-react';
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core';
import { useNavigate, useMatch,useResolvedPath } from "react-router-dom";

interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  link: string;
}

function MainLink({ icon, color, label, link }: MainLinkProps) {
  const resolved = useResolvedPath(link);
  const navigate = useNavigate()
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <UnstyledButton
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
        backgroundColor: !match ? 'inherit' : theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]
      })}
      onClick={()=>{navigate(link)}}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}

const data = [
  { icon: <CurrentLocation size={16} />, color: 'blue', label: 'Current Sprint', link: '/currentSprint' },
  { icon: <Subtask size={16} />, color: 'blue', label: 'Sprints', link: '/sprints' },
  { icon: <Mail size={16} />, color: 'blue', label: 'Email builder', link: '/emailBuilder' }
];

export function MainLinks() {
  const links = data.map((link) => <MainLink {...link} key={link.label} />);
  return <div>{links}</div>;
}