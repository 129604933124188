import moment from "moment";
import { Sprint } from "../../../models/sprint";
import { Task } from "../../../models/task";

interface EmailBuilderProps {
    tasks: Task[]
    sprint: Sprint,
    report: 'rc' | 'prod'
} 

export const rcReleaseEmail = ({tasks, sprint} :EmailBuilderProps) =>{
    const template = `
    <mjml>
       <mj-head>
          <mj-title>Webtrack Release</mj-title>
          <mj-font name="Roboto" href="https://fonts.googleapis.com/css?family=Roboto:300,500"></mj-font>
          <mj-attributes>
             <mj-all font-family="'Helvetica Neue', Helvetica, Arial, sans-serif"></mj-all>
             <mj-text font-weight="400" font-size="16px" color="#000000" line-height="24px" font-family="'Helvetica Neue', Helvetica, Arial, sans-serif"></mj-text>
             <mj-section padding="0px"></mj-section>
          </mj-attributes>
       </mj-head>
       <mj-body background-color="#fff"> 
          <mj-wrapper >
          <mj-section>
             <mj-column width="60%">
                <mj-text font-size="10px">Release log</mj-text>
             </mj-column>
             <mj-column width="40%">
                <mj-image href="#" src="https://d27khucful1bsg.cloudfront.net/itrack/ce2a0f897c3/img/logo.itrack.png"></mj-image>
             </mj-column>
          </mj-section>
         <mj-section padding-top="100px">
             <mj-column width="100%">
             </mj-column>
          </mj-section>
            
          </mj-wrapper>
          <mj-section>
             <mj-column width="45%">
                <mj-divider border-width="2px" border-color="#69e14a" ></mj-divider>
                <mj-text align="center" font-weight="500" padding="0px" font-size="18px">Release ${moment(sprint.releaseDate).format('DD/MM/YYYY')}</mj-text>
                <mj-divider border-width="2px" border-color="#69e14a"></mj-divider>
             </mj-column>
          </mj-section>
          <mj-section padding-top="30px">
             <mj-column width="100%">
                <mj-text>
                   <p>Dear users,</p>
                   <p>Today we will deploy a new release candidate in https://rc.atmstechnology.com, it will include the next changes:</p>
                </mj-text>
             </mj-column>
          </mj-section>
            
         
          <mj-section>
             <mj-column>
                <mj-table>
                   <tr  style="background: #369730; color:#FFFFFF;text-align:center;padding:15px 0;">
                      <th  style="width: 50px; border-radius:10px 10px 10px 0px">TYPE</th>
                      <th style="border-right: 4px solid #fff;border-left: 4px solid #fff; border-radius:10px 10px 10px 0px">CHANGE</th>
                      <th style="width: 80px; border-radius:10px 10px 10px 0px">RELATED TO</th>
                   </tr>
                   ${tasks.map(task =>{
                     return `<tr style="text-align:center; ">
                        <td style="background: #f8f8f8;border-top: 4px solid #FFFFFF; border-bottom: 4px solid #FFFFFF;">${task.task_type.Name}</td>
                        <td style="background: #f8f8f8;border-top: 4px solid #FFFFFF; border-bottom: 4px solid #FFFFFF;border-left: 4px solid #FFFFFF;border-right: 4px solid #FFFFFF;"><span style="font-weight:bold">${task.title}</span>: ${task.description}</td>
                        <td style="background: #f8f8f8;border-top: 4px solid #FFFFFF; border-bottom: 4px solid #FFFFFF;">${task.relatedTo? task.relatedTo : '-'}</td>
                      </tr>`
                   }).join('')}
                </mj-table>
             </mj-column>
          </mj-section>
          
          <mj-section padding-top="30px">
            <mj-column width="100%">
              <mj-text>
                <p><span style="font-weight: 600">Release time:</span> 14:00 GMT</p>
              </mj-text>
            </mj-column>
          </mj-section>
          <mj-section>
             <mj-column width="100%">
                <mj-divider border-width="1px" border-color="#E0E0E0"></mj-divider>
             </mj-column>
          </mj-section>
          <mj-section>
             <mj-column width="75%">
                <mj-text>
                   <h3 style="font-weight: bold; margin-top: 0; margin-bottom: 0"> <a href="https://blog.recast.ai/module-faster-shadow/" style="color: #3498DB; text-decoration: none">
                      Modules, are you faster than your shadows?
                      </a> 
                   </h3>
                   <p style="font-size: 14px">As a developer, should you use modules or code this bit of feature by yourself? Let's find out.</p>
                </mj-text>
             </mj-column>
             <mj-column width="25%">
                <mj-image width="100px" href="https://blog.recast.ai/module-faster-shadow/" src="https://cdn.recast.ai/newsletter/183.png"></mj-image>
             </mj-column>
          </mj-section>
          <mj-section>
             <mj-column width="100%">
                <mj-divider border-width="1px" border-color="#E0E0E0"></mj-divider>
             </mj-column>
          </mj-section>
          <mj-section>
             <mj-column>
                <mj-text padding-bottom="0px">
                   <h3 style="font-weight: bold"> <a href="https://chatbotsmagazine.com/the-implications-of-a-i-on-the-future-chatbots-eaedaf20bfb7#.28dezxtns" style="color: #3498DB; text-decoration: none;">
                      The implications of AI on the future chatbots
                      </a> 
                   </h3>
                </mj-text>
                <mj-image width="600px" padding-top="0" href="https://chatbotsmagazine.com/the-implications-of-a-i-on-the-future-chatbots-eaedaf20bfb7#.28dezxtns" src="https://cdn.recast.ai/newsletter/187.jpg"></mj-image>
                <mj-text>
                </mj-text>
             </mj-column>
          </mj-section>
       </mj-body>
    </mjml>
    `;
    /*
    const res = await axios.post('https://api.mjml.io/v1', {
        mjml: template
    }, {
    auth: {
        username: '4dd9b1e9-efde-4742-869d-c40d00df5461',
        password: 'dfcad8d2-4662-42a2-8cd2-3a462b8a50f9'
    }
    });
*/

    return template;
}; 