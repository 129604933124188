import { useRecoilValue } from 'recoil';
import { currentTasksState } from '../../store/atoms/task';
import { currentSprintState } from '../../store/atoms/sprint';
import EmailBuilder from '../../components/Sprint/EmailBuilder';


function Dashboard () {
    const tasks = useRecoilValue(currentTasksState);
    const currentSprint = useRecoilValue(currentSprintState);
    
    if (tasks.error) {
        return (<>Error loading Sprint Tasks</>)
    }
    
    return (<>
        {tasks.loading? 'loading': <EmailBuilder tasks={tasks.tasks} sprint={currentSprint}/>}
    </>)
}



export default Dashboard;