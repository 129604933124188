let JWT :string = '';

export function getJWT() : string {
    if (!JWT.length) {
        JWT = String(localStorage.getItem('access-token') || '');
    }
    return JWT;
}

export function setJWT(token: string) : void {
    localStorage.setItem('access-token', token);
}

function removeJWT() : void {
    localStorage.removeItem('access-token');
}

export function loggedIn() : boolean {
    return !!getJWT().length;
}


export function logout() : void {
    removeJWT();
}