import { Text } from '@mantine/core';
import { useRecoilValue} from 'recoil';
import { currentSprintState } from '../../../store/atoms/sprint';
import moment from 'moment';

export const CurrentSprintIndicator = () => {
    const currentSprint = useRecoilValue(currentSprintState);
    
    return(<>
    {currentSprint.loading
        ? <></>
        : <>
            <Text color='blue' weight={700} >Sprint: {currentSprint.title}</Text>
            <Text color='blue' weight={700}>Release Date: {moment(currentSprint.releaseDate).format('DD/MM/YYYY')}</Text>
        </>
    }
    </>)
}