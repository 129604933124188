import { gql } from "@apollo/client"

export const TasksbySprint = gql`
query ($sprintId: String!) {
    tasks(where: {sprint: $sprintId}) {
      title
      task_type {
        Name
      }
      description
      relatedTo
      jiraId
    }
  }
`;