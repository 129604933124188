import { useRecoilValue } from 'recoil';
import { currentTasksState } from '../../store/atoms/task';
import { SprintTable } from '../../components/Sprint/SprintsTable';
import { GetAllSprints, GetAllSprintsData } from '../../api/queries/sprint';
import { useQuery } from '@apollo/client';

function Sprints () {
    const { data: sprintData, loading: sprintloading, error: sprintError } =  useQuery<GetAllSprintsData>(GetAllSprints);
    
    if (sprintError) {
        return (<>Error loading Sprints.</>)
    }
    
    return (<div >
        {sprintloading? 'loading': <SprintTable sprints={sprintData!.sprints}/>}
    </div>)
}

export default Sprints;