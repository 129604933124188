import { TextInput, Alert, PasswordInput, Button, Group, Box, createStyles, Grid, useMantineColorScheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Authenticate } from '../../api/mutations/user';
import { useMutation } from '@apollo/client';
import { loggedIn, setJWT } from '../../helpers/Authentication';
import { AlertCircle } from 'tabler-icons-react';
import { Logo } from '../../components/Layout/Logo';

const useStyles = createStyles((theme) => ({
    loginContainer: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[1],
      borderRadius: '9px',
      border: `1px solid ${theme.colors.dark[1]}`,
      padding: '20px',
      width: '15%',
      margin: ' auto 0'
    },
    logo: {
      textAlign: 'center'
    }
  }));
  

function Demo() {
  const { colorScheme } = useMantineColorScheme();
  const [authenticate, { data, loading, error }] = useMutation(Authenticate);
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
 
  if(data) {
    setJWT(data.login.jwt);
  }
  if (loggedIn()) {
      window.location.href = '/';
      return <></>;
  }
  
  return (
    <Grid sx={(theme)=>({
        height: '100%'
    })}>
        <Box sx={{ maxWidth: 500}} mx="auto" className={classes.loginContainer}>
          <div className={classes.logo}>
            <Logo  colorScheme={colorScheme} />
          </div>
        <form onSubmit={form.onSubmit((values) => {authenticate({variables: {email: values.email, password: values.password}})})}>
            <TextInput
            required
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps('email')}
            />

            <PasswordInput
            required
            label="Password"
            {...form.getInputProps('password')}
            />

            <Group position="right" mt="md">
            <Button loading={loading} type="submit">knok knok!</Button>
            </Group>
        </form>
        {error ? 
          <Alert sx={(theme)=>({margin: '10px 0px'})} icon={<AlertCircle size={16} />} title="Pip Pip Pip!" color="red">
            To avoid self destruction, please use the right credentials.
          </Alert>
        : ''}
        </Box>
    </Grid>
        
  );
}

export default Demo;