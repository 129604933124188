import { useRecoilValue } from 'recoil';
import { currentTasksState } from '../../store/atoms/task';
import { Table } from '@mantine/core';
import { Anchor } from '@mantine/core';
import { Task } from '../../models/task';

interface TaskTableBySprintProps {
    tasks: Task[]
} 

function TaskTableBySprint ({ tasks }: TaskTableBySprintProps) {
    const rows = tasks.map((task, index)=> (<tr key={index}>
        <td><Anchor href={`https://atmstechnology.atlassian.net/browse/IT-${task.jiraId}`} target="_blank">{`IT-${task.jiraId}`}</Anchor></td>
        <td>{task.task_type.Name}</td>
        <td>{task.title}</td>
        <td>{task.description}</td>
        <td>{task.relatedTo}</td>
    </tr>));
    
    return (<Table highlightOnHover>
            <thead>
                <tr>
                    <th>Jira ID</th>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Related to</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>)
}

function Dashboard () {
    const tasks = useRecoilValue(currentTasksState);
    
    if (tasks.error) {
        return (<>Error loading Sprint Tasks</>)
    }
    
    return (<>
        {tasks.loading? 'loading': <TaskTableBySprint tasks={tasks.tasks}/>}
    </>)
}

export default Dashboard;